/**
 * Timetastic
 * Features > Slack Tools page
 */
import React, { useEffect } from "react"
// import { Link } from "gatsby"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import FeaturesCrossLinks from "src/components/featuresCrossLinks"
import FeaturesQuotes from "src/components/featuresQuotes"
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import FreeTrialAlt from "src/components/freeTrialAlt"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import SignupButton from "src/components/signupButton"

// Styles
import "src/styles/templates/features.scss"

// SEO
const title = "Slack Vacation Calendar. For work Absences."
const description =
  "Timetastic + Slack vacation calendar. Track your work holidays and vacations in Slack using the Timetastic integration."

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO title={title} useTitleTemplate={false} description={description} />
      <Layout>
        <Header />
        <FooterCta />
        <main className="c-features-main">
          <section className="c-section">
            <div className="u-inner u-inner--l">
              <div className="c-features-intro is-unanimated">
                <div className="c-features-intro__description">
                  <h1 className="h h2 c-features-intro__title">Slack Tools</h1>
                  <p className="c-features-intro__summary">
                    Get a daily update of absences in Slack, you can even move
                    the entire holiday approval process away from email and into
                    Slack.
                  </p>
                  <p>
                    If you love Slack, then dealing with absence requests in
                    your direct messages is a big win.
                  </p>
                  <p>
                    <SignupButton buttonCopy="Start a free trial today" trackCtaLabel="FeaturesSignUp" />

                  </p>
                </div>
                <div className="c-features-intro__media-slack">
                  <img
                    loading="eager"
                    alt="Timetastic"
                    className="c-features-intro__media-slack-1"
                    src="/images/features/slack-tools/timetastic-thumb.png"
                    width={300}
                    heigh={300}
                  />
                  <img
                    loading="eager"
                    alt="Heart"
                    className="heart-icon"
                    src="/images/features/slack-tools/heart-icon.svg"
                    width={64}
                    heigh={59}
                  />
                  <img
                    loading="eager"
                    alt="Slack"
                    className="c-features-intro__media-slack-3"
                    src="/images/features/slack-tools/slack-octothorpe.png"
                    width={300}
                    heigh={300}
                  />
                </div>
              </div>
            </div>
          </section>

          <FeaturesQuotes
            quotes={[
              {
                avatar: "/images/features/slack-tools/guy-armitage-zealous.jpg",
                company: "Zealous",
                name: "Guy Armitage",
                quote:
                  "The daily post in Slack means I always know who’s not working that day, brilliant!",
              },
              {
                avatar: "/images/features/slack-tools/sue-keogh-sookio.jpg",
                company: "Sookio",
                name: "Sue Keogh",
                quote:
                  "It’s just seamless - we don’t have to switch between tools and break concentration.",
              },
            ]}
          />

          <section className="c-section c-features-xyz">
            <div className="u-inner u-inner--l">
              <div className="c-features-grid__item">
                <div className="c-features-grid__media">
                  <img
                    loading="eager"
                    alt="Daily summary in Slack"
                    className="c-features-grid__image"
                    src="/images/features/slack-tools/daily-summary.png"
                    width={539}
                    height={280}
                  />
                </div>
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">Daily Summary</h2>
                  <p className="c-features-grid__title">
                    Be in-the-know with a daily summary of who’s absent today
                  </p>
                  <p>
                    Every morning a quick update to confirm who’s off work
                    today. No need to check your calendars or ask around the
                    office. A nice touch of clarity to start the day.
                  </p>
                </div>
              </div>
              <div className="c-features-grid__item">
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">
                    Absence Requests
                  </h2>
                  <p className="c-features-grid__title">
                    Approve or decline requests in Slack
                  </p>
                  <p>
                    When someone requests time off you’ll be notified instantly
                    in Slack. All it takes is a single click to Approve or
                    Decline. So much better than paperwork.
                  </p>
                </div>
                <div className="c-features-grid__media">
                  <img
                    loading="lazy"
                    alt="Absence requests in Slack"
                    className="c-features-grid__image"
                    src="/images/features/slack-tools/absence-requests.png"
                    width={539}
                    height={298}
                  />
                </div>
              </div>
              <div className="c-features-grid__item">
                <div className="c-features-grid__media">
                  <img
                    loading="lazy"
                    alt="Installing Slack"
                    className="c-features-grid__image"
                    src="/images/features/slack-tools/installing-slack.png"
                    width={539}
                    height={332}
                  />
                </div>
                <div className="c-features-grid__description">
                  <h2 className="c-features-grid__pre-title">
                    Installing Slack
                  </h2>
                  <p className="c-features-grid__title">
                    It couldn’t be simpler
                  </p>
                  <ol className="u-number-circle-list">
                    <li>Start a Timetastic account</li>
                    <li>Go to SETTINGS &gt; INTEGRATIONS</li>
                    <li>Click “Add to Slack”</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <FeaturesCrossLinks hidden="Slack Tools" />

          <FreeTrialAlt
            body="If you’re still wondering about something in Timetastic, or need to see how something works. Just shout, we’re here to help."
            buttonCopy="Start free trial today"
            title="If you’re ready, give it a try now"
          />
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default;
